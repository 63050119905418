import { notifications as n } from "@mantine/notifications";
import { IconAlertCircleFilled as a, IconCircleCheckFilled as c, IconCircleXFilled as i, IconInfoCircleFilled as s } from "@tabler/icons-react";
import t from "react";
import { t as o } from "./vanilla-BVQNXG0s.mjs";
import { T as m } from "./Text-D75zcpkn.mjs";
var f = { root: "_1u5mhob0" };
const p = {
  warning: o.colors.warning[1],
  info: o.colors.positive[1],
  error: o.colors.negative[1],
  neutral: o.colors.neutral[2]
}, u = {
  warning: /* @__PURE__ */ t.createElement(a, null),
  info: /* @__PURE__ */ t.createElement(c, null),
  error: /* @__PURE__ */ t.createElement(i, null),
  neutral: /* @__PURE__ */ t.createElement(
    s,
    {
      color: "black",
      stroke: "black",
      style: { color: "black", stroke: "black", fill: "black" }
    }
  )
}, b = (e, { message: l, duration: r }) => {
  n.show({
    withCloseButton: !1,
    h: "46px",
    autoClose: r ?? 3e3,
    message: /* @__PURE__ */ t.createElement(m, { c: e === "neutral" ? "black" : "neutral.0", size: "md" }, l),
    color: "transparent",
    icon: u[e],
    loading: !1,
    classNames: { root: f.root },
    styles: {
      root: { backgroundColor: p[e], width: "fit-content" },
      description: { color: o.colors.neutral[0] },
      icon: {
        marginLeft: 0,
        marginRight: o.spacing.sm,
        width: "20px",
        height: "20px"
      }
    }
  });
};
export {
  b as t
};
