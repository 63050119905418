import { Dispatch, SetStateAction } from 'react';
import {
  BulkUpload,
  DocumentOperationsProvider,
  FileToUpload,
} from '@huspy/forge/features';
import useGetUploadPreSignedUrls from '@modules/documents/hooks/mutations/useGetUploadPreSignedUrls';
import useUploadFileToS3 from '@modules/documents/hooks/mutations/useUploadFileToS3';
import useCategorizeDocuments from '@modules/documents/hooks/mutations/useCategorizeDocuments';
import useDownloadDocument from '@modules/documents/hooks/mutations/useDownloadDocument';
import { RequiredDocuments } from '@modules/documents/api/types';
import useDeleteDocument from '@modules/documents/hooks/mutations/useDeleteDocuments';

type Props = {
  applicantId: string;
  documents: RequiredDocuments;
  oppId: string;
};

export const BulkUploadWrapper = ({ applicantId, documents, oppId }: Props) => {
  const { mutateAsync: getGetUploadPresignedUrls } = useGetUploadPreSignedUrls(
    oppId,
    applicantId
  );
  const { mutateAsync: uploadFileWithPresignedUrl } = useUploadFileToS3(
    oppId,
    applicantId
  );

  const fetchPresignedUrls = async (files: FileToUpload[]) =>
    getGetUploadPresignedUrls({
      opportunityExternalID: oppId,
      opportunityApplicantExternalID: applicantId,
      filesNames: files.map((file) => file.fileName),
    });

  const uploadSingleFile = async (
    file: FileToUpload | undefined,
    presignedUrl: { url: string; fields: any },
    setNumUploadedDoc: Dispatch<SetStateAction<number>>
  ) => {
    if (!file) {
      return;
    }
    await uploadFileWithPresignedUrl({
      url: presignedUrl.url,
      fields: presignedUrl.fields,
      opportunityExternalID: oppId,
      opportunityApplicantExternalID: applicantId,
      body: file.body,
    });
    setNumUploadedDoc((prev) => prev + 1);
  };

  const uploadDocumentToS3 = async (
    files: FileToUpload[],
    setNumUploadedDoc: Dispatch<SetStateAction<number>>,
    reset: () => void
  ) => {
    if (files.length === 0) return;

    try {
      const presignedUrls = await fetchPresignedUrls(files);

      await Promise.all(
        presignedUrls.response.map((res, idx) =>
          uploadSingleFile(files[idx], res, setNumUploadedDoc))
      );
    } catch {
      reset();
      throw new Error('Error while uploading the document');
    }
  };

  return (
    <DocumentOperationsProvider
      operations={ {
        useCategorizeDocuments,
        useDownloadDocument,
        useDeleteDocument,
      } }
    >
      <BulkUpload
        key={ applicantId }
        applicantId={ applicantId }
        oppId={ oppId }
        documents={ documents }
        uploadDocumentToS3={ uploadDocumentToS3 }
      />
    </DocumentOperationsProvider>
  );
};
