import {
  APPLICANT_TYPE, DOCUMENT_CATEGORY, DOCUMENT_STATUS
} from './const';

export type ApplicantType = typeof APPLICANT_TYPE[keyof typeof APPLICANT_TYPE];
export type DocumentStatus = typeof DOCUMENT_STATUS[keyof typeof DOCUMENT_STATUS];
export type DocumentCategory = typeof DOCUMENT_CATEGORY[keyof typeof DOCUMENT_CATEGORY];

export type Document = {
  id: string;
  document_type_id: number;
  document_category_name: DocumentCategory;
  document_url: string;
  opportunity_applicant_document_id: string;
  name: string;
  status: DocumentStatus;
  document_name: string;
  invalid_reason: string;
  has_extracted_data: boolean;
  created_at?: string;
};

export type RequiredDocument = {
  document_type_name: string;
  max_files: number;
  min_files: number;
  documents: Document[];
  document_type_id: number;
  status?: DocumentStatus;
};

type DocumentType<C extends DocumentCategory> = C extends 'uncategorized'
  ? Document
  : RequiredDocument;

export type RequiredDocuments = {
  [C in DocumentCategory]: DocumentType<C>[];
};

export type Applicant = {
  applicant_id: string;
  applicant_type: ApplicantType;
  first_name: string;
  last_name: string;
  email: string;
  required_documents: RequiredDocuments;
};

export type Vault = {
  applicants: Applicant[];
};

// Type Guard to check if it Document or RequiredDocument type
export function isRequiredDocument(category: Document | RequiredDocument): category is RequiredDocument {
  return (category as RequiredDocument).document_type_name !== undefined;
}
