import { coreApi } from '@modules/core/api';
import { useQuery } from '@tanstack/react-query';

const useGetFeatureFlagsDirectly = (userId: string, flagKey: string) => {
  const {
    data, isLoading, error, refetch, isRefetching,
  } = useQuery({
    queryKey: ['directFeatureFlag', userId, flagKey],
    queryFn: () => coreApi.getFeatureFlagDirectly(userId, flagKey),
    retry: 0,
    enabled: !!userId,
  });

  return {
    data,
    isLoading,
    error,
    refetch,
    isRefetching,
  };
};

export default useGetFeatureFlagsDirectly;
